<template lang="html">

  <section class="cp_GenericContent">
    <div class="cp_GenericContent__inner">
      
    </div>
  </section>

</template>

<script lang="js">

import { computed } from "vue";

  export default  {
    name: 'generic-content', 
    props: {
      compData: {
        type: Object,
        required: true
      }
    },

    setup (props) {
      const contentData = computed(() => {
        return props.compData 
      })

      return {
        contentData
      }
    }
}


</script>

<style scoped deep lang="scss">
  @import "GenericContent.scss";
</style>
