<template lang="html">
  <div class="cp_CompWrapper ob_Card">
    <div class="cp_CompWrapper__header">
      <h2 class="cp_CompWrapper__compTitle">{{ componentName }}</h2>
      <div v-if=componentClasses class="cp_CompWrapper__classes">
        <Lozenge v-for="classLabel in componentClasses" :key="classLabel" :labelText="classLabel" icon="class" theme="grey"></Lozenge>
      </div>
    </div>

    <div v-if="getComponentType(compData.type)" class="cp_CompWrapper__component">
      <component :is="getComponentType(compData.type)" :compData="compData" ></component>
    </div>

    <div class="cp_CompWrapper__details">
      <CodeViewer :codeData="compData" toggleLabel="Component JSON"></CodeViewer>
    </div>
  </div> 
</template>

<script lang="js">

import { computed } from "vue";

import getComponentName from "@/composables/GetComponentName";
import formatComponentName from "@/composables/FormatComponentName";
import Lozenge from "@/components/Lozenge/Lozenge";
import TextContent from "@/components/TextContent/TextContent";
import ImageContent from "@/components/ImageContent/ImageContent";
import ContactContent from "@/components/ContactContent/ContactContent";
import SideBySide from "@/components/SideBySide/SideBySide";
import GenericContent from "@/components/GenericContent/GenericContent";

import CodeViewer from "@/components/CodeViewer/CodeViewer";  

//import FormatComponentName from "@/composables/FormatComponentName";

  export default  {
    name: 'ComponentWrapper',
    props: {
      compData: {
        type: Object,
        required: true // User can accept a userData object on params, or not. It's totally optional.
      }
    },

    components: {
      Lozenge,
      CodeViewer,
      TextContent,
      ImageContent,
      ContactContent,
      SideBySide,
      GenericContent
    },

    setup(props) {
      const getComponentType = getComponentName;

      const componentName = formatComponentName(props.compData.type);

      const componentClasses = computed(() => {
        let classString = props.compData.cssClasses;
        let classArray = null;

        if(classString && classString.length) {
          classArray = props.compData.cssClasses.split(' ');
          // Remove empty entries in the array
          classArray = classArray.filter(function (el) {
            return el != null;
          });
        }

        return classArray;
      })

      return {
        getComponentType,
        componentName,
        componentClasses
      }
    }
}


</script>

<style scoped lang="scss">
  @import "ComponentWrapper.scss";
</style>
