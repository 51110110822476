<template lang="html">

  <section class="cp_ContactContent">
    <h1>Contact Content Component</h1>
    <pre>{{ compData }}</pre>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'contact-content', 
    props: {
      compData: {
        type: Object,
        required: true
      }
    },

    setup () {

    }
}


</script>

<style scoped lang="scss">
  @import "ContactContent.scss"
</style>
