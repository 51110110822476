<template lang="html">
  <div class="cp_PageBuilder">
    <header class="cp_PageBuilder__header">
      <div class="cp_PageBuilder__headerInner ob_Card">
        <div class="cp_PageBuilder__headerContent">
          <h3 class="cp_PageBuilder__pageCategory" v-if=pageType>{{pageType}}</h3>
          <h1 class="cp_PageBuilder__pageTitle">{{ pageData.title }}</h1>
          <div class="cp_PageBuilder__url">
            <span class="cp_PageBuilder__reportLabel">Location</span>
            <Lozenge :labelText="pageURL" size="large" theme="green"></Lozenge>
          </div>

          <div v-if="pageSections" class="cp_PageBuilder__composition">
            <div class="cp_PageBuilder__compositionSection">
              <span class="cp_PageBuilder__reportLabel">Page content is composed of</span> 
              <Lozenge v-if=pageSectionReport :labelText="pageSectionReport" size="large" theme="teal"></Lozenge>
              <Lozenge v-if=pageComponentReport :labelText="pageComponentReport" size="large" theme="teal"></Lozenge>
            </div>

            <div v-if="popupSections" class="cp_PageBuilder__compositionSection">
              <span class="cp_PageBuilder__reportLabel">Popup content is composed of</span> 
              <Lozenge v-if=popupSectionReport :labelText="popupSectionReport" size="large" theme="purple"></Lozenge> 
              <Lozenge v-if=popupComponentReport :labelText="popupComponentReport" size="large" theme="purple"></Lozenge>
            </div>

          </div>
        </div>
        <div class="cp_PageBuilder__pageCode">
          <CodeViewer :codeData="pageCode" toggleLabel="Page JSON"></CodeViewer>
        </div>
      </div>
    </header>

    <div v-if="pageSections && popupSections" class="cp_PageBuilder__contentTabs">
      <a :class="tabState.pageClass" class="cp_PageBuilder__tab" href="#" @click.prevent="handleTabClick('page')">Page Content</a>
      <a :class="tabState.popupClass" class="cp_PageBuilder__tab" href="#" @click.prevent="handleTabClick('popup')">Popup Content</a>
    </div>

    <div v-if="tabState.activeTab == 'page'" class="cp_PageBuilder__contentSection">
      <PageSection v-for="section in pageSections" :key="pageSections.indexOf(section)" :sectionData="section" sectionLabel="Page Section" sectionTheme="teal"></PageSection>
    </div>
    <div v-if="tabState.activeTab == 'popup'" class="cp_PageBuilder__contentSection">
      <PageSection v-for="section in popupSections" :key="popupSections.indexOf(section)" :sectionData="section" sectionLabel="Popup Section" sectionTheme="purple"></PageSection>
    </div>
  </div>
</template>

<script lang="js">

import { computed, reactive } from "vue";
import PageSection from "@/components/PageSection/PageSection";
import Lozenge from "@/components/Lozenge/Lozenge";
import CodeViewer from "@/components/CodeViewer/CodeViewer";

  export default  {
    name: 'PageBuilder',
    components: {
      PageSection,
      Lozenge,
      CodeViewer
    },
    props: {
      pageData: {
        type: Object,
        required: true
      }
    },

    setup(props) {
      const activeClass = 'cp_PageBuilder__tab--active';

      const tabState = reactive({
        activeTab: "page",
        pageClass: 'cp_PageBuilder__tab--active',
        popupClass: false
      })

      const handleTabClick = function(tab) {
        tabState.activeTab = tab;

        if(tab == 'page') {
          tabState.pageClass = activeClass;
          tabState.popupClass = false;
        } else if (tab == 'popup') {
          tabState.pageClass = false;
          tabState.popupClass = activeClass;
        }
      }

      // Count values
      const pageSectionReport = computed(() => {
        if(props.pageData.sections) {
          let sectionCount = props.pageData.sections.length;
          return getReport(sectionCount,"Section");     
        }

        return false;
      })

      const pageComponentReport = computed(() => {
        let compCount = 0;

        props.pageData.sections.forEach(section => {
          if(section.contents && section.contents.length)
          compCount = compCount + section.contents.length;
        })

        if(compCount > 0) {
          return getReport(compCount,"Component");     
        }
        return false;
      })

      const popupSectionReport = computed(() => {
        if(props.pageData.popup) {
          let sectionCount = props.pageData.popup.sections.length;
          return getReport(sectionCount,"Section");     
        }

        return false;
      })

      const popupComponentReport = computed(() => {
        let compCount = 0;

        props.pageData.popup.sections.forEach(section => {
          compCount = compCount + section.contents.length;
        })

        if(compCount > 0) {
          return getReport(compCount,"Component");     
        }
        return false;
      })

      const pageType = computed(() => {
        if(props.pageData.pageType === 'page') {
          return 'Standard Page';
        } else if (props.pageData.pageType === 'article') {
          return 'Blog Article';
        }

        return false;
        
      })

      const pageURL = computed(() => {
        return props.pageData.location;
      })

      const pageSections = computed(() => {
        return props.pageData.sections; 
      })

      const popupSections = computed(() => {
        if(props.pageData.popup) {
          return props.pageData.popup.sections; 
        } 

        // Reset tabs to 'page' mode so page sections doesn't disappear when
        // the page is changed when viewing popup content.
        handleTabClick('page');
        return false;
      })

      const pageCode = computed(() => {
        let editedPageCode = JSON.parse(JSON.stringify(props.pageData));

        // Remove sections of the JSON that are displayed elsewhere
        delete editedPageCode.sections;
        delete editedPageCode.popup;
        delete editedPageCode.pageType;

        return editedPageCode;
      })

      const getReport = function(count,property) {
        let suffix = "";

        if(count > 0) {
          if(count > 1) {
            suffix = "s";
          } 
          
          return `${count} ${property}${suffix}`;
        } 

        return false;
      }

      return {
        pageType:pageType,
        pageURL:pageURL,
        pageSectionReport:pageSectionReport,
        pageComponentReport:pageComponentReport,
        popupSectionReport:popupSectionReport,
        popupComponentReport:popupComponentReport,
        pageSections:pageSections, 
        popupSections:popupSections,
        pageCode:pageCode,
        tabState:tabState,
        handleTabClick:handleTabClick
      }
    }
  }


</script>

<style scoped lang="scss">
  @import "PageBuilder.scss";
</style>
