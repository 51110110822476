<template lang="html">
  <nav class="cp_FeedNavMenu">
    <div class="cp_FeedNavMenu__inner">
      <h3 class="cp_FeedNavMenu__title" v-if=menuTitle >{{ menuTitle }}</h3>
      <ul class="cp_FeedNavMenu__menu">
        <NavMenuNode v-for="item in menu" :linkData=item :key="menu.indexOf(item)" nodeType="navigation"></NavMenuNode>
      </ul>
    </div>
  </nav>
</template>

<script lang="js">

  import { computed } from "vue";
  import useSiteFeed from "@/store/FeedDataStore";
  import NavMenuNode from "@/components/NavMenuNode/NavMenuNode";

  export default  {
    name: 'FeedNavigationMenu',
    components: {
      NavMenuNode
    },
    props: {
      menuTitle: {
        type: String,
        required: false
      },
      menuType: {
        type: String,
        required: true
      }
    },
        
    setup(props) {
      const { getNavTree, getArticleList, getOtherPages } = useSiteFeed();

      const menu = computed(() => {
        let menuTree;

        if(props.menuType === 'pages') {
          menuTree = getNavTree.value;
        } else if(props.menuType === 'articles') {
          menuTree = getArticleList.value;
        } else if(props.menuType === 'other') {
          menuTree = getOtherPages.value;
        } else if(props.menuType === 'site') {
          menuTree = [{url: "/", path: "", label: "Site Information"}];
        }
        
        return menuTree;
      })

      return {
        menu
      }
    }
}


</script>

<style scoped lang="scss">
  @import "FeedNavigationMenu.scss";
</style>
