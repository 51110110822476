<template lang="html">

  <section class="cp_TextContent">
    <div class="cp_TextContent__inner">
      <ContentNode nodeTitle="header" :nodeContent=textTitle></ContentNode>
      <ContentNode nodeTitle="subHeader" :nodeContent=textSubTitle></ContentNode>
      <ContentNode nodeTitle="text" :nodeContent=textBody></ContentNode>
    </div>
  </section>

</template>

<script lang="js">

import { computed } from "vue";

import ContentNode from "@/components/ContentNode/ContentNode";

  export default  {
    name: 'text-content', 
    components: {
      ContentNode
    },
    props: {
      compData: {
        type: Object,
        required: true
      }
    },

    setup (props) {
      const textTitle = computed(() => {
        if (props.compData.header) {
          return `<h2>${props.compData.header}</h2>`;
        } 
        return false;
      })

      const textSubTitle = computed(() => {
        if (props.compData.subHeader) {
          return `<h3>${props.compData.subHeader}</h3>`;
        } 
        return false;
      })

      const textBody = computed(() => {
        if (props.compData.text) {
          return props.compData.text;
        } 
        return false;
      })

      return {
        textTitle,
        textSubTitle,
        textBody
      }
    }
}


</script>

<style scoped deep lang="scss">
  @import "TextContent.scss";
</style>
