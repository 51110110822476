<template lang="html">
  <section class="cp_SiteInfo" v-if=showStatus>
    <!-- API Status -->
    <section class="cp_SiteInfo__section">
      <div class="cp_SiteInfo__sectionContent ob_Card">
        <h1 class="cp_SiteInfo__pageTitle">Site Information</h1>
        <div class="cp_SiteInfos__info">
          <span class="cp_SiteInfo__infoLabel">Feed Size</span>
          <Lozenge :labelText=feedSize theme="green" size="large"></Lozenge>
        </div>
        <div v-if=false class="cp_SiteInfo__endpointAddress"><strong> {{ apiStatusMessage }}<a class="cp_SiteInfo__rawFeedLink" :href=apiEndpoint target="_blank"></a></strong></div>
      </div> 
    </section>

    <PageSection v-for="section in siteSections" :key="siteSections.indexOf(section)" :sectionData="section" :sectionLabel="section.label" sectionTheme="teal"></PageSection>
  </section>
</template>
<script>

import { computed } from "vue";
import useSiteFeed from "@/store/FeedDataStore";
import Lozenge from "@/components/Lozenge/Lozenge";
import PageSection from "@/components/PageSection/PageSection";


export default {
  name: "SiteInfo",
  components: {
    PageSection,
    Lozenge
  },
  props: {

  },

  setup() {

    const { getViewMode, getHeaderSection, getFooterSection, getFeedSize } = useSiteFeed();

    const pageMode = computed(() => {
      return false;
    })

    const showStatus = computed(() => {
      if(getViewMode.value == 'info') {
        return true;
      }
      return false;
    })

    const siteSections = computed(() => {
      const headerData = getHeaderSection.value;
      const footerData = getFooterSection.value;
      const siteSections = [];

      // Build site sections from Header and Footer info, to pass to PageSection component
      // - Section Info
      // -- Components
      if(getHeaderSection.value) {

        // Build header section
        // - cssClasses
        // - content []
        // -- logo
        // -- callToActionButton
        // -- ribbonBanner

        const headerSection = {};

        headerSection.label = 'Site Header';
        headerSection.cssClasses = headerData.cssClasses;
        headerSection.contents = [];

        if(headerData.logo) {
          headerSection.contents.push(headerData.logo);
        }

        if(headerData.callToActionButton) {
          headerSection.contents.push(headerData.callToActionButton);
        }

        if(headerData.ribbonBanner) {
          headerSection.contents.push(headerData.ribbonBanner);
        }
        
        siteSections.push(headerSection);      

        // Build footer section
        // - cssClasses
        // - content []
        // -- logo
        // -- infoLinks
        // -- contactUs
      }

      if(getFooterSection.value) {

        const footerSection = {};

        footerSection.label = 'Site Footer';
        footerSection.cssClasses = footerData.cssClasses;
        footerSection.contents = [];

        footerSection.sectionContent = [];
        footerSection.sectionContent.push({title: 'Copyright Message',content: `${footerData.copyright}`});

        if(footerData.logo) {
          footerSection.contents.push(footerData.logo);
        }

        if(footerData.infoLinks) {
          footerSection.contents.push(footerData.infoLinks);
        }

        if(footerData.contactUs) {
          footerSection.contents.push(footerData.contactUs);
        }

        siteSections.push(footerSection);
      }
      
      return siteSections;
    }) 

    const feedSize = computed(() => {
        return `${getFeedSize.value} KB`;
      })

    return {
      pageMode,
      showStatus,
      feedSize,
      siteSections
    }

  }
}
</script>

<style scoped lang="scss">
  @import "SiteInfo.scss";
</style>