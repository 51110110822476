<template lang="html">
  <section class="cp_PageSection">
    <div class="cp_PageSection__inner ob_Card">
      <div class="cp_PageSection__info">
        <h2 class="cp_PageSection__title">{{sectionLabel}} 
          <Lozenge v-if=sectionID :labelText="sectionID" :theme="sectionTheme"></Lozenge>
        </h2>        
        <div v-if=sectionClasses class="cp_PageSection__classes">
          <Lozenge v-for="classLabel in sectionClasses" :key="classLabel" :labelText="classLabel" icon="class" :theme="sectionTheme"></Lozenge>
        </div>
      </div> 
      <div class="cp_PageSection__sectionContent" v-if=sectionContent>
        <ContentNode v-for="node in sectionContent" :key="sectionContent.indexOf(node)" :nodeTitle=node.title :nodeContent=node.content></ContentNode>
      </div>
      <ComponentWrapper v-for="comp in secComps" :key="comp.uuid" :compData="comp"></ComponentWrapper>
    </div>
  </section>
</template>

<script lang="js">

import { computed } from "vue";
import Lozenge from "@/components/Lozenge/Lozenge";
import ComponentWrapper from "@/components/ComponentWrapper/ComponentWrapper";
import ContentNode from "@/components/ContentNode/ContentNode";

  export default  {
    name: 'PageSection',
    props: {
      sectionLabel: {
        type: String,
        required: true
      },
      sectionTheme: {
        type: String,
        required: true
      },
      sectionData: {
        type: Object,
        required: true // User can accept a userData object on params, or not. It's totally optional.
      }
    },
    components: {
      Lozenge,
      ComponentWrapper,
      ContentNode
    },

    setup(props) {
      const sectionID = computed(() => {
        if(props.sectionData.section_id) {
          return "ID: " + props.sectionData.section_id;
        } 
        return false;
      })

      const sectionContent = computed(() => {
        if (props.sectionData.sectionContent) {
          return props.sectionData.sectionContent
        }
        return false;
      })

      const sectionClasses = computed(() => {
        if(props.sectionData.cssClasses && props.sectionData.cssClasses.length > 0) {
          let classArray = props.sectionData.cssClasses.split(" ");

          classArray = classArray.filter(function (el) {
            return el != null;
          });

          return classArray;
        } else {
          return false;
        }
      })

      const secComps = computed(() => { 
        return props.sectionData.contents;
      })

      return {
        sectionID,
        sectionClasses,
        sectionContent,
        secComps
      }
    }
}


</script>

<style scoped lang="scss">
  @import "PageSection.scss";
</style>
