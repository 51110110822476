<template lang="html">

  <section class="cp_ErrorInfo">
    <div class="cp_ErrorInfo__inner ob_Card">
      <h1 class="cp_ErrorInfo__pageTitle">Sorry. There was a problem loading this feed.</h1>
      <div class="cp_ErrorInfo__info">
        <span class="cp_ErrorInfo__infoLabel">Error Code</span>
        <Lozenge :labelText=apiStatusCode theme="red" size="large"></Lozenge>
      </div>
    </div> 
  </section>
</template>

<script lang="js">

import { computed } from "vue";
import useSiteFeed from "@/store/FeedDataStore";
import Lozenge from "@/components/Lozenge/Lozenge";

  export default  {
    name: 'api-status',
    props: [],
    components: {
      Lozenge
    },
    setup() {
      const { getAPIStatus, getFeedSize } = useSiteFeed();

      const apiEndpoint = computed(() => {
        let apiStatus = getAPIStatus.value;
        return apiStatus.endpointURL;
      })

      const apiStatusClass = computed(() => {
        let apiStatus = getAPIStatus.value;
        return 'cp_ErrorInfo__indicator--' + apiStatus.status;
      })

      const apiStatusCode = computed(() => {
        let apiStatus = getAPIStatus.value;

        return apiStatus.statusCode;
      })

      const feedSize = computed(() => {
        return `${getFeedSize.value} KB`;
      })

      const apiStatusMessage = computed(() => {
        return "";
      })

      return {
        apiEndpoint,
        apiStatusClass,
        apiStatusMessage,
        apiStatusCode,
        feedSize
      }
    }
}


</script>

<style scoped lang="scss">
  @import "ErrorInfo.scss";
</style>
