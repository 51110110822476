<template>
  <div id="app" class="wrapper">
    <header class="ob_Section--nav ob_Section">
      <ThePageHeader pageTitle="Feed Tester"></ThePageHeader>
      <AppNavMenu v-if=feedLoaded></AppNavMenu> 
      <FeedNavigationMenu v-if=feedLoaded menuTitle="Non-Navigation Pages" menuType="other"></FeedNavigationMenu>
      <FeedNavigationMenu v-if=feedLoaded menuTitle="Feed Navigation Menu" menuType="pages"></FeedNavigationMenu>
      <FeedNavigationMenu v-if=feedLoaded menuTitle="Blog Articles" menuType="articles"></FeedNavigationMenu>

      <!-- <FeedSiteMapMenu></FeedSiteMapMenu> -->
    </header>

    <section class="ob_Section--loader">
      <FeedLoaderForm></FeedLoaderForm>
    </section>

    <main class="ob_Section--main ob_Section">
      <PageBuilder v-if=pageToDisplay :pageData="pageToDisplay"></PageBuilder>
      <SiteInfo v-if=showSiteInfo></SiteInfo>
      <ErrorInfo v-if=showErrorInfo></ErrorInfo>
    </main>

  </div>
</template>

<script>

import { computed } from "vue";
import mitt from 'mitt';

import useSiteFeed from "@/store/FeedDataStore";
import { onBeforeMount } from "vue";
import ThePageHeader from "@/components/ThePageHeader/ThePageHeader";
import SiteInfo from "@/components/SiteInfo/SiteInfo";
import ErrorInfo from "@/components/ErrorInfo/ErrorInfo";
import PageBuilder from "@/components/PageBuilder/PageBuilder";
import AppNavMenu from "@/components/AppNavMenu/AppNavMenu";
import FeedNavigationMenu from "@/components/FeedNavigationMenu/FeedNavigationMenu";
// import FeedSiteMapMenu from "@/components/FeedSiteMapMenu/FeedSiteMapMenu";
import FeedLoaderForm from "@/components/FeedLoaderForm/FeedLoaderForm";

const emitter = mitt();

export default {
  name: "App",
  components: {
    ThePageHeader,
    AppNavMenu,
    FeedNavigationMenu,
    //FeedSiteMapMenu,
    FeedLoaderForm,
    SiteInfo,
    ErrorInfo,
    PageBuilder
  },
  provide: {
    emitter: emitter
  },
  setup() {
    const { getFeedData, getPageToView, getViewMode, getAPIStatus } = useSiteFeed();

    // Scroll to top of page on page change    
    const eventBus = emitter;

    eventBus.on('page-change',() => window.scrollTo(0,0)); 
    
    const pageToDisplay = computed(() => {
      let page = getPageToView.value;

      if(page && getViewMode.value === 'page') {
        return page;
      } else {
        return false;
      }      
    })

    const showSiteInfo = computed(() => { 
      let status = getViewMode.value;

      if(status === 'info') {
        return true;
      } else {
        return false;
      }
    })

    const showErrorInfo = computed(() => { 
      let status = getViewMode.value;

      if(status === 'error') {
        return true;
      } else {
        return false;
      }
    })

    const feedLoaded = computed(() => {
      let apiStatus = getAPIStatus.value;
      if(apiStatus.status === 'loaded') {
        return true;      
      } else {
        return false;
      }
    })

    onBeforeMount(() => {
      getFeedData();
    });

    return {
      pageToDisplay,
      showSiteInfo,
      showErrorInfo,
      feedLoaded
    }
  },
};
</script>

<style lang="scss">
  @import "./assets/scss/components/base-content"; 
  @import "./assets/scss/components/base-document";
  @import "./assets/scss/components/base-forms";
  
  @import "./assets/scss/components/ob_Section";
  @import "./assets/scss/components/ob_Label"; 
  @import "./assets/scss/components/ob_Card";
  @import "./assets/scss/components/ob_Image";

  @import "./assets/scss/components/theme-layout"; 
  @import "./assets/scss/components/theme-typography";
  @import "./assets/scss/components/theme-colours"; 
</style>
