<template lang="html">
  <section class="cp_FeedLoader">
    <form class="cp_FeedLoader__form" id="feedLoader" @submit.prevent="loadFeed">
      <div class="cp_FeedLoader__status"><span v-bind:class="apiStatusClass" class="cp_FeedLoader__statusLight"></span></div>
      <fieldset class="cp_FeedLoader__fieldGroup">
        <label class="cp_FeedLoader__label" for="feedURL">Enter the feed URL</label>
        <input class="cp_FeedLoader__field" type="text" id="feedURL" v-model="feedURL" placeholder="Enter a feed URL" :disabled="isDisabled === true" />
      </fieldset>  
      <input class="cp_FeedLoader__button" type="submit" value="Load Feed" v-if="isDisabled === false" />
      <input class="cp_FeedLoader__button--clear cp_FeedLoader__button" type="submit" value="Clear Feed" v-if="isDisabled === true" @click.prevent="clearFeedForm" />
    </form>
  </section>
</template>

<script lang="js">

import { ref, computed } from "vue";
import useSiteFeed from "@/store/FeedDataStore";

  export default  {
    name: 'FeedLoaderForm',
    props: {
      
    },

    setup () {
      const { getFeedData, getAPIStatus, clearFeed } = useSiteFeed();

      let feedURL = ref('');

      const loadFeed = function() {
        getFeedData(feedURL.value);
      }

      const clearFeedForm = function () {
        feedURL.value = '';
        clearFeed();
      }

      const apiStatusClass = computed(() => {
        let apiStatus = getAPIStatus.value;
        return 'cp_FeedLoader__statusLight--' + apiStatus.status;
      })

      const isDisabled = computed (() => {
        let apiStatus = getAPIStatus.value;
        if(apiStatus.status === 'loaded') {
          return true;
        }
        return false;
      })

      return {
        feedURL,
        loadFeed,
        clearFeedForm,
        apiStatusClass,
        isDisabled
      }
    }
}


</script>

<style scoped lang="scss">
  @import "FeedLoaderForm.scss"
</style>
