<template lang="html">
  <nav class="cp_AppNav">
    <div class="cp_AppNav__inner">
      <ul class="cp_AppNav__menu">
        <li class="cp_AppNav__item">
          <a href="" :class="[ isActive ]" class="cp_AppNav__link" @click.prevent=handleSiteInfoClickEvent()>Site Information</a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>

import { computed, inject } from "vue";
import useSiteFeed from "@/store/FeedDataStore";

export default {
  name: "AppNavMenu",
  props: {

  },

  setup() { 
    const { setViewMode, getViewMode } = useSiteFeed();
    const eventBus = inject('emitter');

    const pageMode = computed(() => {
      return false;
    })

    const handleSiteInfoClickEvent = function() {
      eventBus.emit('page-change');
      setViewMode('info');
    }

    const isActive = computed(() => {
      let activeClass = null;
      
      if(getViewMode.value === 'info'){
        activeClass = 'cp_AppNav__link--active';
      }

      return activeClass;
    })

    return {
      pageMode,
      handleSiteInfoClickEvent,
      isActive
    }

  }
}
</script>

<style scoped lang="scss">
  @import "AppNavMenu.scss";
</style>