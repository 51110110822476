<template lang="html">
  <header class="cp_PageHeader--proto cp_PageHeader">
      <div class="cp_PageHeader__inner--proto cp_PageHeader__inner">
        <h1 class="cp_PageHeader__name--proto cp_PageHeader__name"> <span class="cp_PageHeader__logo"><img class=" ob_Image" src="./good_logo_white.svg" width="687" height="388" alt="Good"></span>  <span>{{ pageTitle }}</span></h1>
        <!-- <a class="cp_PageHeader__logo--proto cp_PageHeader__logo" href="/"><img class="ob_Image" src="./good_logo_white.svg" width="687" height="388" alt="Good"> <span>Apps</span></a>  -->
      </div>
  </header>
</template>

<script lang="js">

  export default  {
    name: 'page-header',
    props: {
      pageTitle: {
        type: String,
        required: true
      }
    }   
  }
</script>

<style scoped lang="scss"> 
  @import "ThePageHeader.scss"; 
</style>
