<template lang="html">
  <span :class="[ themeClass, sizeClass, iconClass]" class="ob_Lozenge">{{ labelText }}</span>
</template>

<script lang="js">

  import { computed } from "vue";

  export default  {
    name: 'Lozenge',
    props: {
      labelText: {
        type: String,
        required: true
      },
      theme: {
        type: String,
        required: false
      },
      size: {
        type:String,
        required: false
      },
      icon: {
        type: String,
        required: false
      }
    },

    setup(props) {
      const themeClass = computed(() => {        
        if(props.theme) {
          return "ob_Lozenge--" + props.theme;
        }
        return false;
      })

      const sizeClass = computed(() => {
        if(props.size) {
          return "ob_Lozenge--" + props.size;
        }
        return false;
      })

      const iconClass = computed(() => {
        if(props.icon) {
          return "ob_Lozenge--icon ob_Lozenge--icon-" + props.icon;
        }
        return false;
      })

      return {
        themeClass,
        sizeClass,
        iconClass
      }
    }
}

</script>

<style scoped lang="scss">
  @import "Lozenge.scss";
</style>
