<template lang="html">

  <section class="cp_ImageContent">
    <div class="cp_ImageContent__inner">
      <ContentNode nodeTitle="Desktop Image" :nodeContent=desktopImagePreview></ContentNode>
      <ContentNode nodeTitle="Desktop Image URL" :nodeContent=desktopImageURL></ContentNode>
      <ContentNode nodeTitle="Desktop Width" :nodeContent=desktopWidth></ContentNode>
      <ContentNode nodeTitle="Desktop Height" :nodeContent=desktopHeight></ContentNode>
      <ContentNode nodeTitle="Mobile Image" :nodeContent=mobileImagePreview></ContentNode>
      <ContentNode nodeTitle="Mobile Image URL" :nodeContent=mobileImageURL></ContentNode>
      <ContentNode nodeTitle="Mobile Width" :nodeContent=mobileWidth></ContentNode>
      <ContentNode nodeTitle="Mobile Height" :nodeContent=mobileHeight></ContentNode>

      <!-- <figcaption class="cp_ImageContent__caption">{{ imageCaption }}</figcaption> -->
    </div>
  </section>

</template>

<script lang="js">

  import { computed } from "vue";

  import ContentNode from "@/components/ContentNode/ContentNode";

  export default  {
    name: 'ImageContent',
    components: {
      ContentNode
    },
    props: {
      compData: {
        type: Object,
        required: true
      }
    },

    setup (props) {
      //const componentElements = [];

      const desktopImagePreview = computed(() => {
        let imageSrc = props.compData.desktopUrl;

        if(imageSrc) {
          return `<img class="ob_Image" src="${imageSrc}" alt="" />`;
        }
        //const processedImageSrc = imageSrc.replace('decoupled.local','decoupled.staging.gd');
        return false;
      })

      const desktopImageURL = computed(() => {
        if (props.compData.desktopUrl) {
          return props.compData.desktopUrl;
        } 
        return false;
      })

      const desktopWidth = computed(() => {
        if (props.compData.width) {
          return props.compData.width;
        } 
        return false;
      })

      const desktopHeight = computed(() => {
        if (props.compData.height) {
          return props.compData.height;
        } 
        return false;
      })

      const mobileImagePreview = computed(() => {
        let imageSrc = props.compData.mobileUrl;

        if(imageSrc) {
          return `<img class="ob_Image" src="${imageSrc}" alt="" />`;
        }
        //const processedImageSrc = imageSrc.replace('decoupled.local','decoupled.staging.gd');
        return false;
      })

      const mobileImageURL = computed(() => {
        if (props.compData.mobileUrl) {
          return props.compData.mobileUrl;
        } 
        return false;
      })

      const mobileWidth = computed(() => {
        if (props.compData.mobileWidth) {
          return props.compData.mobileWidth;
        } 
        return false;
      })

      const mobileHeight = computed(() => {
        if (props.compData.mobileHeight) {
          return props.compData.mobileHeight;
        } 
        return false;
      })

      const altText = computed(() => {
        return props.compData['alt'];
      })

      const imageCaption = computed(() => {
        return props.compData['title'];
      })

      const contentData = computed(() => {
        return props.compData 
      })

      return {
        desktopImageURL,
        desktopImagePreview,
        desktopWidth,
        desktopHeight,
        mobileImageURL,
        mobileImagePreview,
        mobileWidth,
        mobileHeight,
        altText,
        imageCaption,
        contentData
      }
    }
}


</script>

<style scoped lang="scss">
  @import "ImageContent.scss";
</style>
