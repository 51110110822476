<template>
  <div class="cp_ContentNode">
    <div class="cp_ContentNode__title ob_Label">{{ nodeTitle }}</div>
    <div class="cp_ContentNode__content" v-if=formattedNodeContent v-html=formattedNodeContent></div>
    <div class="cp_ContentNode__content" v-else>
      <Lozenge :labelText="'Not set'" theme="red" icon="negative"></Lozenge> 
    </div>
  </div>
</template>

<script>

import { computed } from "vue";

import Lozenge from "@/components/Lozenge/Lozenge";

export default {
  name:"ContentNode",
  components: {
    Lozenge
  },
  props: {
    nodeTitle: {
      type: String,
      required:true
    },
    nodeContent: {
      required: false
    }
  },

  setup(props) {
    const formattedNodeContent = computed(() => {

      let nodeContent;

      if(props.nodeContent && props.nodeContent !== 'undefined') {
        nodeContent = props.nodeContent;
      } else {
        nodeContent = false;
      }

      return nodeContent;
    })

    return {
      formattedNodeContent
    }
  }
}
</script>

<style scoped lang="scss">
  @import "ContentNode.scss";
</style>