<template>
  <div class="ob_SourceCode">
    <pre class="ob_SourceCode__formatter"><code class="language-json">{{ codeToFormat }}</code></pre>
  </div>
</template>

<script>

import { onMounted } from "vue";

import Prism from 'prismjs'

import 'prismjs/components/prism-json';
import '@/components/SourceCode/prismTheme.scss';

export default {
  name: "SourceCode",
  props: {
    codeToFormat: {
      type: Object,
      required:true 
    }
  },

  setup() {
    onMounted(() => {
      Prism.highlightAll();
    });
  }

}
</script>

<style scoped lang="scss">
  @import "SourceCode.scss";
</style>