export default function GetComponentName(feedType) {
  if(feedType === 'image-content') {
    return 'ImageContent';
  } else if (feedType === 'side-by-side-content') {
    return 'SideBySide';
  } else if (feedType === 'text-content') {
    return 'TextContent';
  } else {
    return false;
  }
}