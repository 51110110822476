import { reactive, toRefs, computed } from "vue";
import axios from "axios";
import searchArrayByItemPropertyValue from "@/composables/SearchArrayByItemPropertyValue";

const FEED_STATE = reactive({
  siteData: {},
  navTree: [],
  sitemap: [],
  articleList: [],
  otherPages: [],
  apiStatus: {
    endpointURL: "",
    status: "start",
    statusCode: null,
  },
  viewMode: "start",
  pageToView: null,
});

export default function useSiteFeed() {
  // Get the list data from a remote location
  const getFeedData = async (endpoint) => {
    const ENDPOINT = endpoint;

    if (ENDPOINT) {
      setEndpoint(ENDPOINT);

      setViewMode("loading");

      axios
        .get(ENDPOINT, { crossdomain: true })
        .then((data) => {
          const siteData = data;
          initialiseSiteData(siteData);
          setAPIStatus("loaded", siteData.status);
          setViewMode("info");
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status) {
            setAPIStatus("error", error.response.status);
          } else {
            setAPIStatus("error", "internal error");
          }
          setViewMode("error");

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  };

  const clearFeed = function() {
    FEED_STATE.siteData = {};
    FEED_STATE.navTree = [];
    FEED_STATE.sitemap = [];
    FEED_STATE.articleList = [];
    FEED_STATE.otherPages = [];
    FEED_STATE.apiStatus = {
      endpointURL: "",
      status: "start",
      statusCode: null,
    };
    FEED_STATE.viewMode = "start";
    FEED_STATE.pageToView = null;
  };

  const initialiseSiteData = function(siteData) {
    if (siteData) {
      FEED_STATE.siteData = siteData.data;
    }

    const SITEMAP_LIST = [];
    let navTree = [];
    const sitePages = FEED_STATE.siteData.pages;

    if (sitePages) {
      sitePages.forEach((page) => {
        if (page !== null) {
          // Create slimmed down object array for generating sitemap
          const sitemapEntry = {
            url: page.location,
            path: page.loc_source,
            label: page.title,
            children: "",
          };

          SITEMAP_LIST.push(sitemapEntry);
        }
      });
    }

    if (FEED_STATE.siteData.header && FEED_STATE.siteData.header.navbar) {
      navTree = FEED_STATE.siteData.header.navbar;
    }

    FEED_STATE.navTree = navTree;
    FEED_STATE.sitemap = SITEMAP_LIST;

    // Create menu for articles
    const ARTICLE_LIST = [];

    if (FEED_STATE.siteData.blogs && FEED_STATE.siteData.blogs.articles) {
      const siteArticles = FEED_STATE.siteData.blogs.articles;

      if (siteArticles) {
        siteArticles.forEach((article) => {
          // Create slimmed down object array for generating sitemap
          const articleInfo = {
            url: article.location,
            path: article.loc_source, 
            label: article.title,
          };

          ARTICLE_LIST.push(articleInfo);
        });
      }

      FEED_STATE.articleList = ARTICLE_LIST;
    }

    // Create menu for other pages
    const OTHER_PAGES = [];

    sitePages.forEach((page) => {
      if (page !== null) {
        if (checkPageExistsInNavigationMenu(page.location, FEED_STATE.navTree) === false) {
          const otherPage = {
            url: page.location,
            path: page.loc_source,
            label: page.title,
          };

          OTHER_PAGES.push(otherPage);
        }
      }
    });

    FEED_STATE.otherPages = OTHER_PAGES;
  };

  const checkPageExists = function(pagePath) {
    if (pagePath.startsWith("/")) {
      let pageSearch = null;
      let articleSearch = null;

      if(FEED_STATE.siteData.pages) {
        pageSearch = searchArrayByItemPropertyValue(pagePath, "location", FEED_STATE.siteData.pages);
      }
      
      if(FEED_STATE.siteData.blogs && FEED_STATE.siteData.blogs.articles) {
        articleSearch = searchArrayByItemPropertyValue(pagePath, "location", FEED_STATE.siteData.blogs.articles);
      }

      if (pageSearch || articleSearch) {
        return "internal";
      }
    } else if (pagePath.startsWith("http")) {
      return "external";
    }

    return false;
  };

  const searchTree = function(element, pagePath) {
    if (element.url == pagePath) {
      return true;
    } else if (element.children && typeof element.children !== String) {
      let result = false;
      element.children.forEach((child) => {
        if (result === true) {
          return result;
        } else {
          result = searchTree(child, pagePath);
        }
      });

      return result;
    } else {
      return false;
    }
  };

  const checkPageExistsInNavigationMenu = function(pagePath, menuObject) {
    let pageExists = false;

    menuObject.forEach((menuNode) => {
      if (!pageExists) {
        pageExists = searchTree(menuNode, pagePath);
      }
    });
    return pageExists;
  };

  const displayFeedPage = function(pagePath) {
    // Set the view mode to "page"
    setViewMode("page");

    let pageSearch = null;
    let articleSearch = null;

    if(FEED_STATE.siteData.pages) {
      pageSearch = searchArrayByItemPropertyValue(pagePath, "location", FEED_STATE.siteData.pages);
    }
    
    if(FEED_STATE.siteData.blogs && FEED_STATE.siteData.blogs.articles) {
      articleSearch = searchArrayByItemPropertyValue(pagePath, "location", FEED_STATE.siteData.blogs.articles);
    }

    if (pageSearch) {
      FEED_STATE.pageToView = pageSearch;
      FEED_STATE.pageToView.pageType = "page";
    } else if (articleSearch) {
      FEED_STATE.pageToView = articleSearch;
      FEED_STATE.pageToView.pageType = "article";
    }
  };

  // Getters
  const getAPIStatus = computed(() => {
    return FEED_STATE.apiStatus;
  });

  const getFeedSize = computed(() => {
    const size = new TextEncoder().encode(JSON.stringify(FEED_STATE.siteData)).length;
    const kiloBytes = size / 1024;
    //const megaBytes = kiloBytes / 1024;

    return Math.floor(kiloBytes);
  });

  const getNavTree = computed(() => {
    return FEED_STATE.navTree;
  });

  const getSitemap = computed(() => {
    return FEED_STATE.sitemap;
  });

  const getArticleList = computed(() => {
    return FEED_STATE.articleList;
  });

  const getOtherPages = computed(() => {
    return FEED_STATE.otherPages;
  });

  const getViewMode = computed(() => {
    return FEED_STATE.viewMode;
  });

  const getPageToView = computed(() => {
    return FEED_STATE.pageToView;
  });

  const getPageType = computed(() => {
    return FEED_STATE.pageToView.pageType;
  });

  const getPageToViewLocation = computed(() => {
    if (FEED_STATE.pageToView && FEED_STATE.pageToView.location) {
      return FEED_STATE.pageToView.location;
    } else {
      return null;
    }
  });

  const getHeaderSection = computed(() => {
    return FEED_STATE.siteData.header;
  });

  const getFooterSection = computed(() => {
    return FEED_STATE.siteData.footer;
  });

  // Setters
  const setEndpoint = function(endpoint) {
    FEED_STATE.apiStatus.endpointURL = endpoint;
  };

  const setViewMode = function(mode) {
    FEED_STATE.viewMode = mode;
  };

  const setAPIStatus = function(status, statusCode) {
    FEED_STATE.apiStatus.status = status;
    FEED_STATE.apiStatus.statusCode = statusCode;
  };

  return {
    ...toRefs(FEED_STATE),
    getFeedData: getFeedData,
    getAPIStatus: getAPIStatus,
    getFeedSize: getFeedSize,
    getNavTree: getNavTree,
    getSitemap: getSitemap,
    getArticleList: getArticleList,
    getOtherPages: getOtherPages,
    getPageToView: getPageToView,
    getPageToViewLocation: getPageToViewLocation,
    getPageType: getPageType,
    getHeaderSection: getHeaderSection,
    getFooterSection: getFooterSection,
    setEndpoint: setEndpoint,
    getViewMode: getViewMode,
    setViewMode: setViewMode,
    setAPIStatus: setAPIStatus,
    displayFeedPage: displayFeedPage,
    clearFeed: clearFeed,
    checkPageExists: checkPageExists,
  };
}
