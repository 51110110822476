<template lang="html">
  <section class="cp_SideBySide">
    <div class="cp_SideBySide__inner">
      <h3 class="cp_SideBySide__header">Left Side Content</h3>

      <div class="cp_SideBySide__content" v-for="comp in leftSideContent" :key="comp.uuid">
        <div class="cp_SideBySide__compHeader">
          <Lozenge :labelText="formatComponentName(comp.type)"></Lozenge>          
        </div>
        <component :is="getSubComponentType(comp.type)" :compData="comp"></component>
      </div>

      <h3 class="cp_SideBySide__header">Right Side Content</h3>

      <div class="cp_SideBySide__content" v-for="comp in rightSideContent" :key="comp.uuid">
        <div class="cp_SideBySide__compHeader">
          <Lozenge :labelText="formatComponentName(comp.type)"></Lozenge>
        </div>
        <component :is="getSubComponentType(comp.type)" :compData="comp"></component>
      </div>

    </div>
  </section>
</template>

<script lang="js">

import { computed } from "vue";

import getComponentName from "@/composables/GetComponentName";
import formatComponentName from "@/composables/FormatComponentName";

import TextContent from "@/components/TextContent/TextContent";
import ImageContent from "@/components/ImageContent/ImageContent";
import ContentNode from "@/components/ContentNode/ContentNode";
import Lozenge from "@/components/Lozenge/Lozenge";

  export default  {
    name: 'SideBySide',
    components: {
      TextContent,
      ImageContent,
      ContentNode,
      Lozenge
    },

    props: {
      compData: {
        type: Object,
        required: true
      }
    },

    setup (props) {
      const getSubComponentType = function(feedType) {       
        const compName = getComponentName(feedType);
        return compName;
      }

      const leftSideContent = computed(() => {
        if(props.compData.leftSide && props.compData.leftSide.contents) {
          return props.compData.leftSide.contents;
        } else if (props.compData.leftSide && props.compData.leftSide.type){
          return makeContentArray(props.compData.leftSide)
        }
        return [];
      })

      const rightSideContent = computed(() => {
        if(props.compData.rightSide && props.compData.rightSide.contents) {
          return props.compData.rightSide.contents;
        } else if (props.compData.rightSide && props.compData.rightSide.type){
          return makeContentArray(props.compData.rightSide)
        }
        return [];
      })

      const makeContentArray = function(content) {
        let container = [];
        container.push(content);
        return container 
      }

      return {
        getSubComponentType:getSubComponentType,
        formatComponentName:formatComponentName,
        leftSideContent:leftSideContent,
        rightSideContent:rightSideContent
      }
    }
}


</script>

<style scoped lang="scss">
  @import "SideBySide";
</style>
