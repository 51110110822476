<template lang="html">

  <li v-bind:class=nodeTypeClass class="cp_NavNode">
    <a :class="[ activeClass, linkStatusClass ]" class="cp_NavNode__link" :href="linkData.url" @click.prevent=handleClickEvent(linkData.url)>{{ linkData.label }}</a>
    <ul v-bind:class=subMenuTypeClass class="cp_NavNode__subMenu" v-if="linkData.children && linkData.children.length">
      <NavMenuNode v-for="child in linkData.children" :linkData=child :key="linkData.children.indexOf(child)" :nodeType="nodeType"></NavMenuNode>
    </ul>  
  </li>

</template> 

<script lang="js">

  import { computed, inject } from "vue";
  import useSiteFeed from "@/store/FeedDataStore";

  export default  {
    name: 'NavMenuNode',
    props: {
      linkData: {
        type: Object,
        required: true
      },
      nodeType: String
    },

    setup(props) {
      const { displayFeedPage, getPageToViewLocation, getViewMode, checkPageExists } = useSiteFeed();

      const eventBus = inject('emitter');

      const nodeTypeClass = computed(() => {
        if(props.nodeType) {
          return 'cp_NavNode--' + props.nodeType;
        } else {
          return "";
        }
      })

      const subMenuTypeClass = computed(() => {
        if(props.nodeType) {
          return 'cp_NavNode__subMenu--' + props.nodeType;
        } else {
          return null;
        }
      })

      const linkStatusClass = computed(() => {
        if(checkPageExists(props.linkData.url) === 'external') {
          return "cp_NavNode__link--external"
        } else if(checkPageExists(props.linkData.url) === 'internal') {
          return false;
        } else {
          return "cp_NavNode__link--invalid"
        }
      })

      const activeClass = computed(() => {
        let currentPageLocation = getPageToViewLocation.value;

        if(props.linkData.url === currentPageLocation && getViewMode.value === 'page') {
          return "cp_NavNode__link--active"
        } else {
          return null;
        }        
      })

      const handleClickEvent = function (pageToLoad) {
        if(checkPageExists(props.linkData.url) === 'external') {
          window.open(props.linkData.url, '_blank');
        } else if(checkPageExists(props.linkData.url) === 'internal') {
          eventBus.emit('page-change');
          displayFeedPage(pageToLoad);
        } else {
          return false;
        }
        
      }

      return {
        nodeTypeClass,
        subMenuTypeClass,
        activeClass,
        handleClickEvent,
        linkStatusClass
      }
    }
  }


</script>

<style scoped lang="scss">
  @import "NavMenuNode";
</style>
