<template>
  <div :class="stateClass" class="cp_CodeViewer">
    <a href="#" class="cp_CodeViewer__toggle" @click.prevent=handleDetailsToggleClick()>{{toggleLabel}}</a>
    <div v-if=state.showDetails class="cp_CodeViewer__code">
      <SourceCode :codeToFormat="codeData"></SourceCode>
    </div>
  </div>
</template>

<script>

import SourceCode from "@/components/SourceCode/SourceCode";

import { computed, reactive, inject } from "vue";

export default {
  name:'CodeViewer',
  components: {
    SourceCode
  },
  props: {
    codeData: {
      type: Object,
      required: true
    },
    toggleLabel: {
      type: String,
      required: true
    }
  },
  setup() {
    
    const eventBus = inject('emitter');
    
    eventBus.on('page-change',() => state.showDetails = false);

    const state = reactive({
      showDetails: false,
    })

    const handleDetailsToggleClick = function () {
      state.showDetails = !state.showDetails;
    }

    const stateClass = computed(() => {
      if(state.showDetails) {
        return 'cp_CodeViewer--open';
      }

      return false;
    })

    return {
      state,
      stateClass,
      handleDetailsToggleClick
    }

  }
}
</script>

<style scoped lang="scss">
  @import "CodeViewer.scss";
</style>